<template>
  <vx-table
    :resource="resource"
    :columns="columns"
  />
</template>

<script>
import { VxTable, VxCellButtons, VxCellLink, VxCellBadges } from '@/components/table'
import { boolStatuses, markets, passDataToResource } from '@/services/resources'
import { sortOrders } from '@/services/table'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'
import { pick } from 'lodash'
import { boolStatusFormat } from '@/services/utils'

export default {
  name: 'Markets',
  components: {
    VxTable,
    VxCellButtons,
    VxCellLink
  },
  setup () {
    const resource = markets.getAll

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'title',
        component: VxCellLink,
        tdAttr: (title, key, { id }) => ({
          label: title,
          subLabel: 'view all skins',
          href: buildRoute(path.marketSkins, { params: { id } })
        })
      },
      { key: 'slug' },
      {
        key: 'is_main',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isMain) => boolStatusFormat(isMain)
      },
      {
        key: 'url',
        component: VxCellLink,
        tdAttr: (url) => ({ label: url, href: url })
      },
      { key: 'price_multiplier' },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const actionButtons = (market) => {
      const { id, is_pricing_enabled, is_trading_enabled } = market
      const restDataPayload = pick(market, ['price_multiplier', 'title', 'url'])

      const payload = (actionPayload) => {
        return {
          requestParams: {
            urlParams: { id },
            data: { ...restDataPayload, ...actionPayload }
          }
        }
      }

      const pricingButton = {
        ...is_pricing_enabled ? buttons.pricingOn : buttons.pricingOff,
        resource: passDataToResource(
          markets.update,
          payload({
            is_trading_enabled,
            is_pricing_enabled: !is_pricing_enabled
          })
        )
      }
      const tradingButton = {
        ...is_trading_enabled ? buttons.tradingOn : buttons.tradingOff,
        resource: passDataToResource(
          markets.update,
          payload({
            is_pricing_enabled,
            is_trading_enabled: !is_trading_enabled
          })
        )
      }

      return [pricingButton, tradingButton]
    }

    return {
      resource,
      columns
    }
  }
}
</script>
